import React from "react";

class DataDisplay extends React.Component {
  calculateRemainingTime(fecha) {
    const currentDate = new Date();
    const [day, month, providedYear] = fecha.split("/").map(Number);
    const currentYear = currentDate.getFullYear();
    const targetYear = providedYear || currentYear + 1; // Use provided year or assume next year

    const targetDate = new Date(targetYear, month - 1, day);
    const timeDifference = targetDate - currentDate;

    const daysRemaining = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
    const monthsRemaining = Math.floor(daysRemaining / 30); // Approximate number of days in a month
    const yearsRemaining = Math.floor(monthsRemaining / 12);

    return {
      days: daysRemaining % 30,
      months: monthsRemaining % 12,
      years: yearsRemaining,
    };
  }

  renderRemainingTime(remainingTime, fecha) {
    if (fecha === "") {
      return <span style={{ color: "red" }}>PONER FECHA</span>;
    }

    if (remainingTime.years > 0) {
      return `${remainingTime.years} Días, ${remainingTime.months} Meses`;
    } else if (remainingTime.months > 0) {
      return `${remainingTime.months} Meses`;
    } else {
      return `${remainingTime.days} Días`;
    }
  }

  getCircleColor(remainingTime, fecha) {
    if (fecha === "") {
      return "red"; // Empty date, show red
    }

    const currentDate = new Date();
    const [day, month, providedYear] = fecha.split("/").map(Number);
    const currentYear = currentDate.getFullYear();
    const targetYear = providedYear || currentYear + 1; // Use provided year or assume next year
    const targetDate = new Date(targetYear, month - 1, day);

    if (targetDate < currentDate) {
      return "black"; // Date has passed, show black
    } else if (
      targetDate.getDate() === currentDate.getDate() &&
      targetDate.getMonth() === currentDate.getMonth() &&
      targetDate.getFullYear() === currentDate.getFullYear()
    ) {
      return "red"; // Same day, show red
    } else if (remainingTime.years > 0) {
      return "white";
    } else if (remainingTime.months > 0) {
      return "green";
    } else if (remainingTime.days > 7) {
      return "yellow";
    } else {
      return "red"; // Default to red for any other cases
    }
  }

  render() {
    const { data } = this.props;

    return (
      <table
        className="alert alert-info m-1 etiqueta-info"
        style={{ padding: "3px", width: "100%" }}
      >
        <tbody>
          {Object.keys(data).map((group) => (
            <React.Fragment key={group}>
              <tr>
                <td colSpan="3">
                  <h2>.:{group}:.</h2>
                </td>
              </tr>
              {Object.keys(data[group]).map((item) => {
                const remainingTime = this.calculateRemainingTime(
                  data[group][item].fecha
                );
                const circleColor = this.getCircleColor(
                  remainingTime,
                  data[group][item].fecha
                );

                return (
                  <tr key={item}>
                    <td>
                      <span>
                        {item} ({data[group][item].fecha}) -{" "}
                      </span>
                      <span>
                        {this.renderRemainingTime(
                          remainingTime,
                          data[group][item].fecha
                        )}
                      </span>
                    </td>
                    <td>
                      <div
                        style={{
                          width: "30px",
                          height: "30px",
                          borderRadius: "50%",
                          marginLeft: "8px",
                          backgroundColor: circleColor,
                        }}
                      />
                    </td>
                  </tr>
                );
              })}
            </React.Fragment>
          ))}
        </tbody>
      </table>
    );
  }
}

export default DataDisplay;
