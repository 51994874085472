import React from "react";
import "./App.css"; // You may need to adjust the import path for your project
import DataDisplay from "./DataDisplay"; // Import your DataDisplay component here

const data = {
  Vehículos: {
    "ITV Gris": { fecha: "15/09/2024" },
    "ITV PiruleiraII": { fecha: "15/06/2024" },
  },
  Niños: {
    "Apuntarlos al comedor": { fecha: "15/04/2024" },
    "Apuntar a Gala al cole": { fecha: "15/05/2024" },
  },
  Carlos: {},
  Morita: {
    "Vacuna rabia": { fecha: "" },
  },
  Pruebas: {
    "Hace un mes": {
      fecha:
        String(new Date().getDate()).padStart(2, "0") +
        "/" +
        String(new Date().getMonth() + 1 - 1).padStart(2, "0") +
        "/" +
        new Date().getFullYear(),
    },
    Hoy: {
      fecha:
        String(new Date().getDate()).padStart(2, "0") +
        "/" +
        String(new Date().getMonth() + 1).padStart(2, "0") +
        "/" +
        new Date().getFullYear(),
    },
    "Mes que viene": {
      fecha:
        String(new Date().getDate()).padStart(2, "0") +
        "/" +
        String(new Date().getMonth() + 1 + 1).padStart(2, "0") +
        "/" +
        new Date().getFullYear(),
    },
    "Año que viene": {
      fecha:
        String(new Date().getDate()).padStart(2, "0") +
        "/" +
        String(new Date().getMonth() + 1).padStart(2, "0") +
        "/" +
        (new Date().getFullYear() + 1),
    },
  },
};

function App() {
  return (
    <div className="App">
      <h1>Operae V0.0.1</h1>
      <DataDisplay data={data} />
    </div>
  );
}

export default App;
